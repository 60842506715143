// stripe plan envs should follow format: essential,professional,vat
let STRIPE_PLANS;
try {
  STRIPE_PLANS = (process.env.NODE_ENV === 'production' ? process.env.STRIPE_PLANS_LIVE : process.env.STRIPE_PLANS_TEST) || '';
} catch {
  STRIPE_PLANS = '';
}


const plans = {
  essential: {
    stripeId: STRIPE_PLANS.split(',')[0],
    planSpecs: {
      siteDefaults: {
        features: {
          customCTAs: false, // separate product
          integrations: false, // GA integrations / plugin callbacks
          live: false,
          logo: false, // cannot change branding
          reports: false,
          timeFilter: true,
          timeFilterMigrated: true,
          userInputs: true,
          floatingShareButtons: false,
          anonymizeFeedback: true,
        },
        report: {
          weekly: false,
          monthly: false,
        },
        live: false,
      },
      organizationDefaults: {
        features: {
          multiSite: false,
        },
      },
      customSetDefaults: {
        features: {
          logo: false, // branding -> always on
          integrations: false, // ga / callbacks disabled in plugin
        },
      },
      limits: {
        contentSections: 1,
        trafficPerMonth: 500000,
      },
    },
  },
  professional: {
    stripeId: STRIPE_PLANS.split(',')[1],
    planSpecs: {
      siteDefaults: {
        features: {
          customCTAs: true,
          integrations: true, // GA integrations / plugin callbacks
          live: true,
          logo: true, // can change branding
          reports: true,
          timeFilter: true,
          timeFilterMigrated: true,
          userInputs: true,
          floatingShareButtons: true,
          anonymizeFeedback: true,
        },
        report: {
          weekly: true,
          monthly: true,
        },
        live: true,
      },
      organizationDefaults: {
        features: {
          multiSite: true,
        },
      },
      customSetDefaults: {
        features: {
          logo: false, // branding on by default
          integrations: true, // ga / callbacks enabled in plugin
        },
      },
      limits: {
        contentSections: 2,
        trafficPerMonth: 2000000,
      },
    },
  },

  additionalContentSection: {
    stripeId: STRIPE_PLANS.split(',')[3],
    linkedProductIds: [
      'additional-content-section-2020',
      'additional-content-section-gbp',
      'askem-lisasivusto',
    ],
    planModifiers: {
      limits: {
        contentSections: 1,
      }
    },
  },
  additionalUser: {
    linkedProductIds: ['askem-lisakayttaja'],
    planModifiers: {
      limits: {
        users: 1,
      }
    },
  },
  askem: {
    solo: {
      linkedProductIds: ['askem-solo-fin', 'askem-solo-uk'],
      planSpecs: {
        siteDefaults: {
          features: {
            customCTAs: false, // separate product
            integrations: false, // GA integrations / plugin callbacks
            live: false,
            logo: false, // cannot change branding
            reports: false,
            timeFilter: true,
            timeFilterMigrated: true,
            userInputs: true,
            floatingShareButtons: false,
            anonymizeFeedback: true,
          },
          report: {
            weekly: false,
            monthly: false,
          },
          live: false,
        },
        organizationDefaults: {
          askem: true,
          askemDetails: {
            plan: 'solo',
          },
          features: {
            multiSite: false,
          },
          askemFeatures: {
            multiSite: false,
            multiUser: false,
            emailNotifications: false,
            feedbackAssigning: false,
            feedbackLabeling: false,
            feedbackNotes: false,
            buttonToolGeneral: false,
            buttonToolSentiment: false,
            buttonToolAfterReactions: false,
            buttonToolHistory: false,
            satisfactionView: false,
            significantChangesReport: false,
          },
        },
        customSetDefaults: {
          features: {
            logo: false, // branding -> always on
            integrations: false, // ga / callbacks disabled in plugin
          },
        },
        limits: {
          contentSections: 1,
          users: 1,
          trafficPerMonth: 100000,
        },
        hardLimits: {
          contentSections: 1,
          users: 2,
        },
      },
    },
    teams: {
      linkedProductIds: ['askem-teams-fin', 'askem-teams-uk'],
      planSpecs: {
        siteDefaults: {
          features: {
            customCTAs: false, // separate product
            integrations: true, // GA integrations / plugin callbacks
            live: false,
            logo: false, // cannot change branding
            reports: false,
            timeFilter: true,
            timeFilterMigrated: true,
            userInputs: true,
            floatingShareButtons: false,
            anonymizeFeedback: true,
          },
          report: {
            weekly: false,
            monthly: false,
          },
          live: false,
        },
        organizationDefaults: {
          askem: true,
          askemDetails: {
            plan: 'teams',
          },
          features: {
            multiSite: true,
          },
          askemFeatures: {
            multiSite: true,
            multiUser: true,
            emailNotifications: true,
            feedbackAssigning: true,
            feedbackLabeling: true,
            feedbackNotes: true,
            buttonToolGeneral: true,
            buttonToolSentiment: true,
            buttonToolAfterReactions: true,
            buttonToolHistory: true,
            satisfactionView: true,
            significantChangesReport: true,
            matomoIntegration: true,
          },
        },
        customSetDefaults: {
          features: {
            logo: false, // branding -> always on
            integrations: true, // ga / callbacks enabled in plugin
          },
        },
        limits: {
          contentSections: 2,
          users: 5,
          trafficPerMonth: 1000000,
        },
        hardLimits: {
          users: 10,
        },
      },
    },
  },
  askemServiceLevels: {
    assisted: {
      linkedProductIds: ['askem-assisted-fin'],
      planModifiers: {
        askemFeatures: { chatSupport: true },
      }
    },
    turnkey: {
      linkedProductIds: ['askem-turnkey-fin'],
      planModifiers: {
        askemFeatures: { chatSupport: true }
      }
    }
  },
};

// enterprise plan starts with essential plan features,
// other features to be tailored by sales people
plans.enterprise = {
  ...plans.essential,
  linkedProductIds: ['enterprise-2020', 'enterprise-2020-gbp'],
  planSpecs: {
    ...plans.essential.planSpecs,
    limits: {
      ...plans.essential.planSpecs.limits,
      // just some randomly high values to simulate "unlimited"
      contentSections: 1000,
      trafficPerMonth: 2000000000,
    },
  },
};

// based on teams, but with higher limits
plans.askem.enterprise = {
  ...plans.askem.teams,
  linkedProductIds: ['askem-enterprise-fin'],
  planSpecs: {
    ...plans.askem.teams.planSpecs,
    organizationDefaults: {
      ...plans.askem.teams.planSpecs.organizationDefaults,
      askemDetails: {
        plan: 'enterprise'
      },
    },
    limits: {
      ...plans.askem.teams.planSpecs.limits,
      // just some randomly high values to simulate "unlimited"
      contentSections: 1000,
      users: 1000,
      trafficPerMonth: 2000000000,
    },
    // no hard limits
    hardLimits: {},
  },
};

plans.askem.light = {
  ...plans.askem.solo,
  linkedProductIds: ['askem-light-fi'],
  planSpecs: {
    ...plans.askem.solo.planSpecs,
    organizationDefaults: {
      ...plans.askem.solo.planSpecs.organizationDefaults,
      askemDetails: {
        plan: 'light'
      },
    },
    limits: {
      ...plans.askem.solo.planSpecs.limits,
      trafficPerMonth: 50000,
    },
  },
};

module.exports = plans;
