const punycode = require('punycode/');
const { askem: askemPlans } = require('../config/plans');

const validateEmail = (email) => {
  // just a very simple sanity check to avoid rejecting valid but rare form email addresses
  if (email.length >= 4 && email.includes('@') && email.includes('.')) {
    return true;
  }
  return false;
};

function idSort(items, ids) {
  // reorder the list items into the order of ids based on attribute _id
  // in each item. create a fake object for ids which were not found
  let map = {};
  for (const item of items) {
    map[item._id] = item;
  }
  return ids.map((_id) => map[_id] || { _id });
}

const parseHostname = (domainInput = '', unicode = false) => {
  let hostname = null;
  try {
    if (domainInput.startsWith('http://') || domainInput.startsWith('https://')) {
      ({ hostname } = new URL(domainInput));
    } else {
      ({ hostname } = new URL(`https://${domainInput}`));
    }
  } catch (e) {
    //
  }

  if (hostname && !hostname.includes('.') && hostname !== 'localhost') {
    return null;
  }

  if (unicode) {
    return punycode.toUnicode(hostname);
  }

  return hostname;
};

// helper for checking which kind of mails (brand) should the user receive
const checkIfAskemRecipient = ({ org, user }) => {
  if (org.askem) {
    if (!(org.askemDetails && org.askemDetails.parallelUse)) {
      return true;
    }
    if (user.askemUser) {
      return true;
    }
  }

  return false;
};

/**
 *
 * @param {object} organization - organization object
 * @param {number} newMemberCount - number of new members to be added, defaults to 0
 *
 * Utility function to check whether organization has limits and if they are reached or will be exceeded. Returns an object with all the relevant information.
*/
const checkLimits = ({ organization = {}, newMemberCount = 0, newContentSectionCount = 0 }) => {
  const { limits = {
    users: 0, contentSections: 0, override: false,
  }, members = [], sites } = organization;

  const { hardLimits } = limits.override
    ? {}
    : (askemPlans[organization.askemDetails?.plan]?.planSpecs || {});

  // member limit check
  const userLimit = hardLimits?.users
    ? Math.min(limits.users, hardLimits.users)
    : limits.users;
  const memberCount = members.length;
  const userLimitReached = !!userLimit && memberCount >= userLimit;
  const exceedsUserLimit = !!userLimit && memberCount + newMemberCount > userLimit;
  const canAddMembers = !userLimitReached;

  // content section limit check
  const contentSectionLimit = hardLimits?.contentSections
    ? Math.min(limits.contentSections, hardLimits.contentSections)
    : limits.contentSections;
  const contentSectionCount = sites.length;
  const contentSectionLimitReached = !!contentSectionLimit
    && contentSectionCount >= contentSectionLimit;
  const exceedsContentSectionLimit = !!contentSectionLimit
    && (contentSectionCount + newContentSectionCount) > contentSectionLimit;
  const canAddContentSections = !contentSectionLimitReached;

  return {
    userLimit,
    memberCount,
    userLimitReached,
    canAddMembers,
    exceedsUserLimit,
    contentSectionCount,
    contentSectionLimit,
    contentSectionLimitReached,
    canAddContentSections,
    exceedsContentSectionLimit,
  };
};

const escapeRegExpString = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const stripProtocol = string => string.replace(/^\w+:\/\//, '');

module.exports = {
  checkIfAskemRecipient,
  checkLimits,
  escapeRegExpString,
  idSort,
  parseHostname,
  stripProtocol,
  validateEmail,
};
